// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-attachments-list',
};

class AcAttachmentsListController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcAttachmentsListController.propTypes = {};

AcAttachmentsListController.defaultProps = {};

export default AcAttachmentsListController;
