// Imports => React
import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcOverviewProjectUpdateController from './ac-overview-project-update.controller.js';

// Imports => Atoms
import { AcContainer } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcImage from '@atoms/ac-image/ac-image.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcNectarSlider from '@components/ac-nectar-slider/ac-nectar-slider.web.js';

@inject('routing')
@observer
class AcOverviewProjectUpdate extends AcOverviewProjectUpdateController {
  renderCoverImage() {
    const { image, featured_images } = this.props;

    if (featured_images && featured_images.length > 0) {
      return (
        <AcNectarSlider
          slides={featured_images}
          callback={this.handleBlazyCallback}
        />
      );
    } else if (
      image &&
      image.responsive &&
      (image.responsive.sm || image.responsive.md)
    ) {
      return (
        <AcImage
          source={image.responsive.sm || image.responsive.md}
          alt={image.label}
          className={this.getBackgroundClassNames()}
          callback={this.handleBlazyCallback}
          parallax={true}
          id={'ac-hero-image'}
        />
      );
    }
  }

  render() {
    const { image, title, location, callback, state, country, t } = this.props;
    let result = [];
    if (location && location !== null && location !== '') {
      result.push(location);
    }
    if (state && state !== null && state !== '') {
      result.push(state);
    }
    if (country && country !== null && country !== '') {
      result.push(country);
    }
    return (
      <div className={this.getStyleClassNames()} ref={node => (this.el = node)}>
        <div
          className={this.getBackgroundWrapClassNames()}
          ref={node => (this.background.wrp = node)}
        >
          {this.renderCoverImage()}
        </div>

        <div className={this.getOverlayClassNames()} />

        <div className={this.getInnerClassNames()}>
          <AcContainer fluid={true} className={this.getContainerClassNames()}>
            <AcHeading rank={1} className={this.getTitleClassNames()}>
              {title}
            </AcHeading>
            <AcHeading rank={2} className={this.getSubTitleClassNames()}>
              {result.join(', ')}
            </AcHeading>
          </AcContainer>
          <AcContainer fluid={true} className={this.getContainerClassNames()}>
            <AcButton
              theme="alpha"
              variant="outline"
              className={this.getButtonClassNames()}
              {...{ callback }}
            >
              {t('Projectupdate')}
            </AcButton>
          </AcContainer>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(AcOverviewProjectUpdate);
