// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-module',
};

class AcAuthStepsController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const { callback } = this.props;

    if (callback) callback(event);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcAuthStepsController.propTypes = {};

AcAuthStepsController.defaultProps = {};

export default AcAuthStepsController;
