// Imports => React
import React from 'react';
import { Fade } from 'react-reveal';

// Import => Utilities
import AcUuid from '@utils/ac-uuid.js';

// Imports => Controller
import AcKPIGroupController from './ac-kpi-group.controller.js';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcKPI from '@atoms/ac-kpi/ac-kpi.web.js';

class AcKPIGroup extends AcKPIGroupController {
  renderKPIs() {
    const { kpis, currency , projectStatus} = this.props;
    return kpis.map((kpi, n) => {
      kpi.id = kpi.id ? kpi.id : AcUuid();
      return (
        <AcColumn
          xs={12}
          {...this.getColumnSizes(projectStatus)}
          className={this.getColumnClassNames(n)}

          key={`kpi-${kpi.id}`}
        >
          <Fade key={kpi.id}>
            <AcKPI {...kpi} currency={currency} key={kpi.id} />
          </Fade>
        </AcColumn>
      );
    });
  }

  render() {
    return (
      <AcRow className={`${this.getStyleClassNames()} ac-kpi-group`}>
        {this.renderKPIs()}
      </AcRow>
    );
  }
}

export default AcKPIGroup;
