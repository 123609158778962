// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-contact',
  IMAGE: {
    MAIN: 'ac-contact__image',
    HOLDER: 'ac-contact__image-holder',
  },
  DETAILS: {
    MAIN: 'ac-contact__details',
    WRP: 'ac-contact__details-wrp',
    NAME: 'ac-contact__details__name',
    LINE: 'ac-contact__details__line',
  },
};

// Controller
class AcContactController extends React.Component {
  constructor(props) {
    super(props);

    this.handleCallback = this.handleCallback.bind(this);
  }

  handleCallback(event) {
    const { id, callback } = this.props;
    if (!id) return;
    if (callback) callback(event, id);
  }

  getContactImageClassNames() {
    return clsx(_CLASSES.IMAGE.MAIN);
  }

  getContactImageHolderClassNames() {
    return clsx(_CLASSES.IMAGE.HOLDER);
  }

  getContactDetailsNameClassNames() {
    return clsx(_CLASSES.DETAILS.NAME);
  }

  getContactDetailsLineClassNames() {
    return clsx(_CLASSES.DETAILS.LINE);
  }

  getContactDetailsWrpClassNames() {
    return clsx(_CLASSES.DETAILS.WRP);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcContactController.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  phonenumber: PropTypes.object,
  email: PropTypes.string,
};

AcContactController.defaultProps = {
  name: 'Naam Partner',
  email: 'invest@realiance.nl',
  phonenumber: {
    pretty: '',
    sanitized: '',
    value: '',
  },
};

export default AcContactController;
