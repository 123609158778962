// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';

// Imports => Controller
import AcParticipationCardController from './ac-participation-card.controller.js';

// Imports => Components
import AcStepper from '@components/ac-stepper/ac-stepper.web.js';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web.js';

import AcImage from '@atoms/ac-image/ac-image.web.js';

import AcHeading from '@atoms/ac-heading/ac-heading.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

@inject('routing', 'store')
@observer
class AcParticipationCard extends AcParticipationCardController {
  render() {
    const { id, cover, name, application } = this.props.project;

    const location = this.getLocation();

    return (
      <AcCard className={this.getStyleClassNames()} id={id}>
        <AcRow noGutters={true}>
          <AcColumn xs={12} {...this.getColumnSize('first')}>
            {cover && cover.responsive.lg && (
              <AcImage
                parallax={true}
                type={'background'}
                source={cover.responsive.lg}
                alt={cover.label}
                wrpClassName={this.getImageClassNames()}
              />
            )}
          </AcColumn>
          <AcColumn xs={12} {...this.getColumnSize('second')}>
            <article className={this.getArticleClassNames()}>
              <div className={this.getHeaderClassNames()}>
                {location && location !== '' && (
                  <AcHeading
                    rank={7}
                    theme={'alpha'}
                    className={this.getSubtitleClassNames()}
                  >
                    {location}
                  </AcHeading>
                )}
                <AcHeading
                  rank={4}
                  variant={'uppercase'}
                  className={this.getTitleClassNames()}
                >
                  {name && name}
                </AcHeading>
              </div>

              <div className={this.getBodyClassNames()}>
                {application && (
                  <>
                    <AcStepper
                      steps={application}
                      interactive={false}
                      indexes={true}
                      size={'default'}
                      titlePlacement={'bottom'}
                    />
                  </>
                )}
              </div>

              <div className={this.getFooterClassNames()}>
                <AcButton
                  theme={'alpha'}
                  variant={'outline'}
                  callback={this.handleCallback}
                  className={'h-margin-top-15'}
                >
                  Details
                  <AcRipple theme={'alpha'} size={'small'} />
                </AcButton>
              </div>
            </article>
          </AcColumn>
        </AcRow>
      </AcCard>
    );
  }
}

export default AcParticipationCard;
