// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-participation-step-confirmation',
  FORM: {
    MAIN: 'ac-participation__form',
    BLOCK: 'ac-participation__form--block',
  },
};

class AcParticipationStepConfirmationController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleSubmit(event) {
    if (event) event.preventDefault();

    const { replace } = this.props.routing;

    if (replace) replace('/');
  }

  async handleDownload(event) {
    if (event) event.preventDefault();

    const { single } = this.store.participation;

    await this.store.participation.download(single.pdf).then(async response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const name = `realiance-participation-${single.id}--${single.date}.pdf`;
      const link = document.createElement('a');
      link.href = url;
      link.name = name;
      link.download = name;

      await link.click();

      window.URL.revokeObjectURL(url);
    });
  }

  getFormClassNames() {
    const { loading } = this.store.participation;
    return clsx(_CLASSES.FORM.MAIN, loading && _CLASSES.FORM.BLOCK);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcParticipationStepConfirmationController.propTypes = {
  callback: PropTypes.func,
};

AcParticipationStepConfirmationController.defaultProps = {
  callback: () => {},
};

export default AcParticipationStepConfirmationController;
