// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcParticipationStepConfirmationController from './ac-participation-step-confirmation.controller.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcDivider from '@atoms/ac-divider/ac-divider.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcIcon from '@atoms/ac-icon/ac-icon.web.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('routing', 'store')
@observer
class AcParticipationStepConfirmation extends AcParticipationStepConfirmationController {
  render() {
    const { loading } = this.store.participation;
    const { t } = this.props;

    return (
      <form
        method={'post'}
        onSubmit={this.handleSubmit}
        className={this.getFormClassNames()}
      >
        <AcContainer fluid={true}>
          <AcRow>
            <AcColumn xs={12} sm={12} md={{ size: 8, offset: 2 }}>
              <AcRow>
                <AcColumn>
                  <AcDivider theme={'light'} className={'h-margin-y-35'} />
                </AcColumn>
              </AcRow>

              <AcRow className={'h-text--align-center'}>
                <AcColumn>
                  <AcHeading
                    rank={4}
                    theme={'white'}
                    className={'h-margin-y-0'}
                  >
                    {t('Bedankt voor uw inschrijving!')}
                  </AcHeading>
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>
                  <AcDivider theme={'light'} className={'h-margin-y-35'} />
                </AcColumn>
              </AcRow>

              <AcRow className={'h-text--align-left'}>
                <AcColumn>
                  <AcRichContent
                    content={t('ParticipationDownloadInstructions')}
                  />
                </AcColumn>
              </AcRow>

              <AcRow className={'h-text--align-left'}>
                <AcColumn md={{ size: 11, offset: 1 }}>
                  <AcButton
                    theme={'alpha'}
                    callback={this.handleDownload}
                    className={'h-margin-y-15'}
                  >
                    <AcIcon
                      icon={'progress-download'}
                      className={'ac-button__icon ac-button__icon--left'}
                    />
                    {t('Document downloaden')}
                    <AcRipple size={'small'} />
                  </AcButton>
                </AcColumn>
              </AcRow>

              <AcRow className={'h-text--align-left'}>
                <AcColumn>
                  <AcRichContent content={t('ParticipationMailInstructions')} />
                </AcColumn>
              </AcRow>

              <AcRow className={'h-text--align-left'}>
                <AcColumn xs={12} sm={6} md={{ size: 5, offset: 1 }}>
                  <AcRichContent
                    content={t('ParticipationEMailInstructions')}
                  />
                </AcColumn>
                <AcColumn xs={12} sm={6} md={{ size: 5 }}>
                  <AcRichContent
                    content={t('ParticipationPostalInstructions')}
                  />
                </AcColumn>
              </AcRow>
            </AcColumn>
          </AcRow>
        </AcContainer>

        {loading && (
          <AcLoader
            theme={'white'}
            coverTheme={'pitch'}
            loading={loading}
            cover={true}
          />
        )}
      </form>
    );
  }
}

export default withNamespaces()(AcParticipationStepConfirmation);
