// Imports => React
import React from 'react';

// Imports => Controller
import AcContactController from './ac-contact.controller';
import AcCard from '@atoms/ac-card/ac-card.web.js';
import AcImage from '@atoms/ac-image/ac-image.web.js';

// Component
class AcContact extends AcContactController {
  render() {
    const { image, name, phonenumber, email } = this.props;

    return (
      <AcCard
        className={this.getStyleClassNames()}
        theme={'pitch'}
        callback={this.handleClickEvent}
      >
        <div className={this.getContactImageHolderClassNames()}>
          {image && (
            <AcImage
              source={image}
              className={this.getContactImageClassNames()}
              alt={name ? name : ''}
              id={'ac-project-contact-img'}
            />
          )}
        </div>
        <div className={this.getContactDetailsWrpClassNames()}>
          {name && (
            <div className={this.getContactDetailsNameClassNames()}>{name}</div>
          )}
          {phonenumber && (
            <div className={this.getContactDetailsLineClassNames()}>
              <a href={`tel:${phonenumber.sanitized}`}>{phonenumber.value}</a>
            </div>
          )}
          {email && (
            <div className={this.getContactDetailsLineClassNames()}>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
        </div>
      </AcCard>
    );
  }
}

export default AcContact;
