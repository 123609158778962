// Imports => React
import React from 'react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcExpandableController from './ac-expandable.controller.js';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web.js';

class AcExpandable extends AcExpandableController {
  render() {
    const { children, title } = this.props;
    return (
      <AcCard className={this.getStyleClassNames()} hoverAnimation={false}>
        <div
          className={this.getTitleClassNames()}
          onClick={this.toggleExpandable}
        >
          {title}
        </div>
        <div
          ref={this.content}
          style={this.getContentInlineStyles()}
          className={this.getContentClassNames()}
        >
          <div className={this.getContentChildrenClassNames()}>{children}</div>
        </div>
      </AcCard>
    );
  }
}

export default withNamespaces()(AcExpandable);
