// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-overview-project-update',
  LOADED: 'ac-overview-project-update--loaded',
  CONTAINER: {
    MAIN: 'ac-overview-project-update__container',
  },
  BACKGROUND: {
    MAIN: 'ac-overview-project-update__background',
    WRAP: 'ac-overview-project-update__background-wrp',
  },
  OVERLAY: {
    MAIN: 'ac-overview-project-update__overlay',
  },
  INNER: {
    MAIN: 'ac-overview-project-update__inner',
    TITLE: 'ac-overview-project-update__title',
    SUBTITLE: 'ac-overview-project-update__subtitle',
    BUTTON: 'ac-overview-project-update__memorandam-button',
  },
};

class AcOverviewProjectUpdateController extends React.Component {
  constructor(props) {
    super(props);

    this._timer = null;
    this._delay = props.routing.history.action === 'PUSH' ? 1000 : 0;

    this.el = false;
    this.background = {
      el: false,
      wrp: false,
    };

    this.state = {
      loaded: false,
      background: {
        styles: '',
      },
    };
  }

  componentDidMount() {
    clearTimeout(this._timer);
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  handleBackButtonCallback = (event, el) => {
    const { backButton } = this.props;
    const { goBack } = this.props.routing;

    if (backButton && goBack) goBack();
  };

  handleBlazyCallback = state => {
    this._timer = setTimeout(() => {
      this.setState({ loaded: state });
    }, this._delay);
  };

  getSubTitleClassNames = () => {
    return clsx(_CLASSES.INNER.SUBTITLE);
  };

  getTitleClassNames = () => {
    return clsx(_CLASSES.INNER.TITLE);
  };

  getButtonClassNames = () => {
    return clsx(_CLASSES.INNER.BUTTON);
  };

  getInnerClassNames = () => {
    return clsx(_CLASSES.INNER.MAIN);
  };

  getOverlayClassNames = () => {
    return clsx(_CLASSES.OVERLAY.MAIN);
  };

  getBackgroundClassNames = () => {
    return clsx(_CLASSES.BACKGROUND.MAIN);
  };

  getBackgroundWrapClassNames = () => {
    return clsx(_CLASSES.BACKGROUND.WRAP);
  };

  getContainerClassNames = () => {
    return clsx(_CLASSES.CONTAINER.MAIN);
  };

  getStyleClassNames = () => {
    const { loaded } = this.state;
    return clsx(_CLASSES.MAIN, loaded && _CLASSES.LOADED);
  };
}

AcOverviewProjectUpdateController.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backButton: PropTypes.bool,
};

AcOverviewProjectUpdateController.defaultProps = {
  image: '',
  title: '',
  backButton: true,
};

export default AcOverviewProjectUpdateController;
