// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Imports => Utilities
import FormatDate from '@utils/ac-format-date.js';

const _CLASSES = {
  MAIN: 'ac-news-card',
  ORIENTATION: {
    HORIZONTAL: 'ac-news-card--horizontal',
    VERTICAL: 'ac-news-card--vertical',
  },
  BADGE: {
    MAIN: 'ac-news-card__badge',
    UNREAD: 'ac-news-card__badge--unread',
  },
  IMAGE: {
    MAIN: 'ac-news-card__image',
    WRP: 'ac-news-card__image-wrp',
  },
  ARTICLE: {
    MAIN: 'ac-news-card__article',
    HEADER: 'ac-news-card__article__header',
    BODY: 'ac-news-card__article__body',
    FOOTER: 'ac-news-card__article__footer',
    TITLE: 'ac-news-card__article__title',
    SUBTITLE: 'ac-news-card__article__subtitle',
    DATE: 'ac-news-card__article__date',
    LOCATION: 'ac-news-card__article__location',
    CONTENT: 'ac-news-card__article__content',
    BUTTON: 'ac-news-card__article__button',
  },
};

const _COLUMNSIZES = {
  HORIZONTAL: 6,
  VERTICAL: 12,
};

// Controller
class AcNewsCardController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleCallback = this.handleCallback.bind(this);
    this.goToItem = this.goToItem.bind(this);
  }

  formatLocation() {
    const { city, state, country } = this.props.post;

    let result = [];

    if (city && city !== null && city !== '') result.push(city);
    if (state && state !== null && state !== '') result.push(state);
    if (country && country !== null && country.name !== '')
      result.push(country.name);

    return result.join(', ');
  }

  formatDate() {
    const { date } = this.props.post;

    return FormatDate(date);
  }

  handleCallback(event) {
    const { id, callback } = this.props;
    if (!id) return;
    if (callback) callback(event, id);
  }

  goToItem() {
    const { id } = this.props;
    const { push } = this.props.routing;

    if (id) push(`/news/${id}`);
  }

  getBadgeClassNames(type) {
    return clsx(
      _CLASSES.BADGE.MAIN,
      type && type === 'unread' && _CLASSES.BADGE.UNREAD
    );
  }

  getButtonClassNames() {
    return clsx(_CLASSES.ARTICLE.BUTTON);
  }

  getContentClassNames() {
    return clsx(_CLASSES.ARTICLE.CONTENT);
  }

  getDateClassNames() {
    return clsx(_CLASSES.ARTICLE.DATE);
  }

  getLocationClassNames() {
    return clsx(_CLASSES.ARTICLE.LOCATION);
  }

  getSubtitleClassNames() {
    return clsx(_CLASSES.ARTICLE.SUBTITLE);
  }

  getTitleClassNames() {
    return clsx(_CLASSES.ARTICLE.TITLE);
  }

  getFooterClassNames() {
    return clsx(_CLASSES.ARTICLE.FOOTER);
  }

  getBodyClassNames() {
    return clsx(_CLASSES.ARTICLE.BODY);
  }

  getHeaderClassNames() {
    return clsx(_CLASSES.ARTICLE.HEADER);
  }

  getArticleClassNames() {
    return clsx(_CLASSES.ARTICLE.MAIN);
  }

  getImageClassNames() {
    return clsx(_CLASSES.IMAGE.MAIN);
  }

  getImageWrpClassNames() {
    return clsx(_CLASSES.IMAGE.WRP);
  }

  getColumnSize() {
    const { orientation } = this.props;

    return _COLUMNSIZES[orientation.toUpperCase()];
  }

  getStyleClassNames() {
    const { orientation, className } = this.props;

    return clsx(
      _CLASSES.MAIN,
      orientation && _CLASSES.ORIENTATION[orientation.toUpperCase()],
      className && className
    );
  }
}

AcNewsCardController.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  post: PropTypes.object,
};

AcNewsCardController.defaultProps = {
  orientation: 'vertical',
  post: {
    unread: false,
    image: false,
    title: '',
    city: '',
    state: '',
    country: '',
    date: '',
    content: '',
  },
};

export default AcNewsCardController;
