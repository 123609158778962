// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcParticipationStepBankAccountController from './ac-participation-step-bankaccount.controller.js';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';

// import AcTextInput from '@atoms/ac-text-input/ac-text-input.web.js';
// import AcRadiobutton from '@atoms/ac-radiobutton/ac-radiobutton.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('store')
@observer
class AcParticipationStepBankAccount extends AcParticipationStepBankAccountController {
  render() {
    const { loading } = this.store.participation;
    const { t } = this.props;

    return (
      <form
        method={'post'}
        onSubmit={this.handleSubmit}
        className={this.getFormClassNames()}
      >
        <AcRow className={'h-margin-bottom-25 h-text--align-center'}>
          <AcColumn xs={12} sm={12} md={12}>
            <AcHeading
              variant={'uppercase'}
              rank={4}
              className={'h-margin-y-0'}
            >
              {t('Bankgegevens')}
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-top-25'}>
          <AcColumn xs={12} sm={6}>
            <AcButton
              theme={'pitch'}
              variant={'transparent'}
              callback={this.handleBackBtn}
            >
              <i className="ac-icon ac-icon--chevron-left ac-button__icon ac-button__icon--left" />
              {t('Terug')}
              <AcRipple theme={'dark'} size={'small'} />
            </AcButton>
          </AcColumn>

          <AcColumn xs={12} sm={6} className={'h-flex-h-align-end'}>
            <AcButton theme={'alpha'} type={'submit'}>
              {t('Volgende stap')}
              <AcRipple size={'small'} />
            </AcButton>
          </AcColumn>
        </AcRow>

        {loading && <AcLoader loading={loading} cover={true} />}
      </form>
    );
  }
}

export default withNamespaces()(AcParticipationStepBankAccount);
