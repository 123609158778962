// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-expandable',
  EXPANDED: 'ac-expandable--open',
  TITLE: 'ac-expandable__title',
  CONTENT: {
    MAIN: 'ac-expandable__content',
    CHILDREN: 'ac-expandable__content__children',
    ANIMATION_BLOCKED: '',
  },
};

class AcExpandableController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expanded,
      contentHeight: 0,
      contentAnimationBlocked: true,
    };

    this.timeout = null;
    this.toggleExpandable = this.toggleExpandable.bind(this);

    this.content = React.createRef();
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      requestAnimationFrame(() => {
        const contentHeight =
          this.content.current && this.content.current.scrollHeight;

        this.setState({
          contentHeight,
          contentAnimationBlocked: false,
        });
      });
    }, 1000); // attempt to keep the fps on 60
  }

  toggleExpandable() {
    const { expanded } = this.state;

    this.timeout = setTimeout(() => {
      requestAnimationFrame(() => {
        this.setState(
          {
            expanded: !expanded,
          },
          () => {
            this.timeout = setTimeout(() => {
              requestAnimationFrame(() => {
                const contentHeight =
                  this.content.current && this.content.current.scrollHeight;

                this.setState(
                  {
                    contentHeight,
                    contentAnimationBlocked: false,
                  },
                  () => {
                    window.dispatchEvent(new Event('resize'));
                  }
                );
              });
            }, 1000); // attempt to keep the fps on 60
          }
        );
      });
    }, 1000 / 60); // attempt to keep the fps on 60
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getStyleClassNames() {
    const { className } = this.props;
    const { expanded } = this.state;

    return clsx(_CLASSES.MAIN, expanded && _CLASSES.EXPANDED, className);
  }

  getTitleClassNames() {
    return clsx(_CLASSES.TITLE);
  }

  getContentClassNames() {
    const { contentAnimationBlocked } = this.state;

    return clsx(
      _CLASSES.CONTENT.MAIN,
      contentAnimationBlocked && _CLASSES.CONTENT.ANIMATION_BLOCKED
    );
  }

  getContentChildrenClassNames() {
    return clsx(_CLASSES.CONTENT.CHILDREN);
  }

  getContentInlineStyles() {
    const { expanded, contentHeight } = this.state;
    const height = this.content.current && this.content.current.scrollHeight;

    return {
      height: expanded ? `${(height || contentHeight) + 'px'}` : 0,
    };
  }
}

AcExpandableController.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

AcExpandableController.defaultProps = {
  expanded: false,
};

export default AcExpandableController;
