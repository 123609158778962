// Imports => React
import React from 'react';

// Imports => Controller
import AcAttachmentsListController from './ac-attachments-list.controller.js';

// Imports => Atoms
import AcAttachment from '@atoms/ac-attachment/ac-attachment.web.js';

class AcAttachmentsList extends AcAttachmentsListController {
  buildAttachments() {
    const { attachments } = this.props;
    return attachments.map((attachment, index) => (
      <AcAttachment {...attachment} key={`ac-attachment-${attachment.id}`} />
    ));
  }

  render() {
    return (
      <div className={this.getStyleClassNames()}>{this.buildAttachments()}</div>
    );
  }
}

export default AcAttachmentsList;
