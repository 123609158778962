// Imports => React
import React from 'react';
import { inject } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcNewsCardController from './ac-news-card.controller';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web.js';

import AcImage from '@atoms/ac-image/ac-image.web.js';

import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

// Component
@inject('routing')
class AcNewsCard extends AcNewsCardController {
  render() {
    const { unread, featured_image, title, content } = this.props.post;
    const { t } = this.props;

    const location = this.formatLocation();
    const date = this.formatDate();

    return (
      <AcCard
        className={this.getStyleClassNames()}
        callback={this.handleClickEvent}
      >
        <AcRow noGutters={true}>
          {featured_image &&
            featured_image.responsive &&
            featured_image.responsive.md && (
              <AcColumn xs={12} sm={this.getColumnSize()}>
                <AcImage
                  type={'background'}
                  source={featured_image.responsive.md}
                  alt={featured_image.label}
                  wrpClassName={this.getImageClassNames()}
                />
              </AcColumn>
            )}
          <AcColumn xs={12} sm={this.getColumnSize()}>
            <article className={this.getArticleClassNames()}>
              <div className={this.getHeaderClassNames()}>
                {date && date !== '' && (
                  <span className={this.getDateClassNames()}>{date}</span>
                )}
                {location && location !== '' && (
                  <AcHeading
                    rank={7}
                    theme={'alpha'}
                    className={this.getLocationClassNames()}
                  >
                    {location}
                  </AcHeading>
                )}
                <AcHeading rank={4} className={this.getTitleClassNames()}>
                  {title && title}
                </AcHeading>
              </div>

              <div className={this.getBodyClassNames()}>
                {content && (
                  <AcRichContent
                    content={content}
                    className={this.getContentClassNames()}
                  />
                )}
              </div>

              <div className={this.getFooterClassNames()}>
                <AcButton
                  theme={'alpha'}
                  variant={'outline'}
                  callback={this.goToItem}
                >
                  {t('Lees meer')}
                  <AcRipple theme={'alpha'} size={'small'} />
                </AcButton>
              </div>
            </article>
          </AcColumn>
        </AcRow>

        {unread && (
          <div className={this.getBadgeClassNames('unread')}>
            {t('Ongelezen')}
          </div>
        )}
      </AcCard>
    );
  }
}

export default withNamespaces()(AcNewsCard);
