// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Imports => Utilities
import AcFormatCurrency from '@utils/ac-format-currency.js';

const _CLASSES = {
  MAIN: 'ac-participation-step-investment',
  FORM: {
    MAIN: 'ac-participation__form',
    BLOCK: 'ac-participation__form--block',
  },
};

class AcParticipationStepInvestmentController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.state = {};

    this.handleBackBtn = this.handleBackBtn.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);

    this.getParticipationsInputOptions = this.getParticipationsInputOptions.bind(
      this
    );
    this.getTotalParticipationsInputOptions = this.getTotalParticipationsInputOptions.bind(
      this
    );
    this.getParticipationTypeInputOptions = this.getParticipationTypeInputOptions.bind(
      this
    );
    this.getAccountInputOptions = this.getAccountInputOptions.bind(this);
  }

  handleBackBtn(event) {
    if (event) event.preventDefault();

    const { step, backbtn } = this.props;

    if (backbtn) backbtn(event, step);
  }

  handleSubmit(event) {
    if (event) event.preventDefault();

    const { step, callback } = this.props;

    if (callback) callback(event, step);
  }

  handleInputChange(event, name, value, type) {
    if (!name) return;

    let newValue = value;
    if (type === 'number')
      newValue = isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10);

    this.store.participation.set(name, newValue);
  }

  handleTypeChange() {
    const { data } = this.store.participation;
    const { user: User } = this.store.auth;

    if (data.account === null) {
      User.accounts
        .filter(account => account.type === data.registration_type)
        .forEach((account, n) => {
          if (n === 0) {
            this.store.participation.set('account', account.id);
          }
        });
    } else {
      let current_account = User.accounts.filter(
        account => account.id === data.account
      );

      if (current_account.type !== data.registration_type) {
        User.accounts
          .filter(account => account.type === data.registration_type)
          .forEach((account, n) => {
            if (n === 0) {
              this.store.participation.set('account', account.id);
            }
          });
      }
    }
  }

  getParticipationsInputOptions() {
    const { data } = this.store.participation;

    return {
      type: 'number',
      value: data.participations || '',
      name: 'participations',
      label: 'Aantal participaties a € 25.000',
      instructions: 'Minimaal 5',
      disabled: false,
      callback: this.handleInputChange,
      validation: value =>
        isNaN(parseInt(value, 10))
          ? 'De ingevulde waarde is niet correct'
          : parseInt(value, 10) < 5
          ? 'Een minimum van 5 participaties is vereist'
          : false,
    };
  }

  getTotalParticipationsInputOptions() {
    const { data } = this.store.participation;

    const total_participations = AcFormatCurrency(data.participations * 25000);

    return {
      type: 'text',
      value: total_participations,
      name: 'total_participations',
      label: 'Totale inleg',
      disabled: true,
      readonly: true,
      callback: () => {},
    };
  }

  getParticipationTypeInputOptions(value) {
    const { data } = this.store.participation;

    this.handleTypeChange();

    return {
      value: value,
      reference: `ac-participation-registration_type-${value}`,
      name: 'registration_type',
      group: 'data.registration_type',
      checked: value === data.registration_type,
      disabled: false,
      callback: this.handleInputChange,
    };
  }

  getAccountInputOptions(value, n) {
    const { data } = this.store.participation;

    return {
      value: value,
      name: 'account',
      group: 'participation.account',
      checked: value === data.account,
      disabled: false,
      callback: this.handleInputChange,
    };
  }

  getFormClassNames() {
    const { loading } = this.store.participation;
    return clsx(_CLASSES.FORM.MAIN, loading && _CLASSES.FORM.BLOCK);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcParticipationStepInvestmentController.propTypes = {
  callback: PropTypes.func,
};

AcParticipationStepInvestmentController.defaultProps = {
  callback: () => {},
};

export default AcParticipationStepInvestmentController;
