// Imports => React
import React, { useCallback } from 'react';
import clsx from 'clsx';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web';
import AcImage from '@atoms/ac-image/ac-image.web';

const _CLASSES = {
  MAIN: 'ac-carousel-item',
  IMAGE: 'ac-carousel-item__image',
};

// Component
const AcCarouselItem = ({
  width,
  height,
  url,
  responsive,
  label,
  name,
  id,
  callback,
  className,
}) => {
  const handleClickEvent = useCallback(event => {
    if (callback) callback(event, label, id);
  }, []);

  const getImageClassNames = useCallback(() => {
    return clsx(_CLASSES.IMAGE);
  }, []);

  const getStyleClassNames = useCallback(() => {
    return clsx([_CLASSES.MAIN, className]);
  }, []);

  return (
    <AcCard
      className={getStyleClassNames()}
      shadow={false}
      width={width}
      height={height}
      callback={handleClickEvent}
    >
      <AcImage
        type={'background'}
        id={`ac-carousel-item-${id}`}
        source={responsive && responsive.md ? responsive.md : url}
        className={getImageClassNames()}
        alt={label}
      />
    </AcCard>
  );
};

export default React.memo(AcCarouselItem);
