// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-project-card',
  ORIENTATION: {
    HORIZONTAL: 'ac-project-card--horizontal',
    VERTICAL: 'ac-project-card--vertical',
  },
  IMAGE: 'ac-project-card__image',
  ARTICLE: {
    MAIN: 'ac-project-card__article',
    HEADER: 'ac-project-card__article__header',
    BODY: 'ac-project-card__article__body',
    FOOTER: 'ac-project-card__article__footer',
    TITLE: 'ac-project-card__article__title',
    SUBTITLE: 'ac-project-card__article__subtitle',
  },
};

const _COLUMNSIZES = {
  HORIZONTAL: {
    SM: {
      FIRST: 5,
      SECOND: 7,
    },
    MD: 6,
  },
  VERTICAL: 12,
};

class AcParticipationCardController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleCallback = this.handleCallback.bind(this);
  }

  getColumnSize(order) {
    const { orientation } = this.props;

    const orient = orientation.toUpperCase();

    if (orient === 'HORIZONTAL') {
      return {
        sm:
          order === 'first'
            ? _COLUMNSIZES.HORIZONTAL.SM.FIRST
            : _COLUMNSIZES.HORIZONTAL.SM.SECOND,
        md: _COLUMNSIZES.HORIZONTAL.MD,
      };
    } else {
      return {
        sm: _COLUMNSIZES.VERTICAL,
        md: _COLUMNSIZES.VERTICAL,
      };
    }
  }

  getLocation() {
    const { city, state, country } = this.props.project;

    let result = [];

    if (city && city !== null && city !== '') result.push(city);
    if (state && state !== null && state !== '') result.push(state);
    if (country && country !== null && country.name !== '')
      result.push(country.name);

    return result.join(', ');
  }

  handleCallback(event, el) {
    const { id } = this.props.project;
    const { push } = this.props.routing;

    if (id) push(`/project/${id}`);
  }

  getSubtitleClassNames() {
    return clsx(_CLASSES.ARTICLE.SUBTITLE);
  }

  getTitleClassNames() {
    return clsx(_CLASSES.ARTICLE.TITLE);
  }

  getFooterClassNames() {
    return clsx(_CLASSES.ARTICLE.FOOTER);
  }

  getBodyClassNames() {
    return clsx(_CLASSES.ARTICLE.BODY);
  }

  getHeaderClassNames() {
    return clsx(_CLASSES.ARTICLE.HEADER);
  }

  getArticleClassNames() {
    return clsx(_CLASSES.ARTICLE.MAIN);
  }

  getImageClassNames() {
    return clsx(_CLASSES.IMAGE);
  }

  getStyleClassNames() {
    const { orientation, className } = this.props;

    return clsx(
      _CLASSES.MAIN,
      orientation && _CLASSES.ORIENTATION[orientation.toUpperCase()],
      className
    );
  }
}

AcParticipationCardController.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  project: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

AcParticipationCardController.defaultProps = {
  orientation: 'horizontal',
  project: {},
};

export default AcParticipationCardController;
