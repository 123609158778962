// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-kpi-group',
  COLUMN: {
    MAIN: 'ac-kpi-group__column h-flex-align',
    ALIGNMENT: {
      START: 'h-flex-h-align-start',
      CENTER: 'h-flex-h-align-center',
      END: 'h-flex-h-align-end',
    },
  },
};

class AcKPIGroupController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getColumnSizes(status = '') {
    const { kpis } = this.props;
    const num = kpis.length;

    const min = {
      sm: 4,
      md: 3,
      lg: 3,
    };

    if (status === 'completed') {
      min.sm = 4;
      min.md = 4;
      min.lg = 4;
    }

    const max = 12;

    const sizes = {
      sm: {
        size: max / num < min.sm ? min.sm : max / num,
      },
      md: {
        size: max / num < min.md ? min.md : max / num,
      },
      lg: {
        size: max / num < min.lg ? min.lg : max / num,
      },
    };

    return sizes;
  }

  getColumnClassNames(n) {
    const { kpis, autoAlign } = this.props;
    const num = kpis.length;

    const pos =
      !autoAlign || n === 0 ? 'start' : n === num - 1 ? 'end' : 'center';

    return clsx([
      _CLASSES.COLUMN.MAIN,
      _CLASSES.COLUMN.ALIGNMENT[pos.toUpperCase()],
    ]);
  }

  getStyleClassNames() {
    const { kpis } = this.props;
    const path = typeof window !== undefined && window.location.pathname;

    return clsx(
      _CLASSES.MAIN + kpis && kpis.length === 6,
      path === '/' && 'dashboard-kpis'
    );
  }
}

AcKPIGroupController.propTypes = {
  autoAlign: PropTypes.bool,
};

AcKPIGroupController.defaultProps = {
  autoAlign: true,
};

export default AcKPIGroupController;
