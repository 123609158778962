// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import { Fade } from 'react-reveal';

// Imports => Controller
import AcParticipationStepInvestmentController from './ac-participation-step-investment.controller.js';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';

import AcTextInput from '@atoms/ac-text-input/ac-text-input.web.js';
import AcRadiobutton from '@atoms/ac-radiobutton/ac-radiobutton.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('store')
@observer
class AcParticipationStepInvestment extends AcParticipationStepInvestmentController {
  render() {
    const { single: Project } = this.store.projects;
    const { user: User } = this.store.auth;
    const { data: Participation, loading } = this.store.participation;
    const { t } = this.props;

    return (
      <form
        method={'post'}
        onSubmit={this.handleSubmit}
        className={this.getFormClassNames()}
      >
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={12} md={7}>
            <AcHeading rank={5} className={'h-margin-y-0'}>
              {t('Met hoeveel participaties wilt u deelnemen aan')}{' '}
              {Project.name}
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-50'}>
          <AcColumn xs={12} sm={12} md={7}>
            <AcTextInput {...this.getParticipationsInputOptions()} />
          </AcColumn>

          <AcColumn xs={12} sm={12} md={5}>
            <AcTextInput {...this.getTotalParticipationsInputOptions()} />
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={12} md={7}>
            <AcHeading rank={5} className={'h-margin-y-0'}>
              {t('Ik schrijf mij in als een')}
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-50'}>
          <AcColumn xs={12} sm={12} md={7}>
            <AcRow>
              <AcColumn xs={12} className={'h-flex-h-align-start'}>
                <AcRadiobutton
                  {...this.getParticipationTypeInputOptions('person')}
                >
                  {t('Natuurlijk persoon')}
                </AcRadiobutton>
              </AcColumn>
              <AcColumn xs={12} className={'h-flex-h-align-start'}>
                <AcRadiobutton
                  {...this.getParticipationTypeInputOptions('corporation')}
                >
                  {t('Rechtspersoon')}
                </AcRadiobutton>
              </AcColumn>
            </AcRow>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={12} md={7}>
            <AcHeading rank={5} className={'h-margin-y-0'}>
              {t('Met')}
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-50'}>
          <AcColumn xs={12} sm={12} md={7}>
            <AcRow>
              {User &&
                User.accounts &&
                User.accounts
                  .filter(
                    account => account.type === Participation.registration_type
                  )
                  .map((account, n) => (
                    <AcColumn
                      xs={12}
                      className={'h-flex-h-align-start'}
                      key={`ac-account-${account.id}`}
                    >
                      <Fade>
                        <AcRadiobutton
                          {...this.getAccountInputOptions(account.id, n)}
                        >
                          {account.name}
                        </AcRadiobutton>
                      </Fade>
                    </AcColumn>
                  ))}
            </AcRow>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-top-25'}>
          <AcColumn xs={12} sm={6}>
            <AcButton
              theme={'pitch'}
              variant={'transparent'}
              callback={this.handleBackBtn}
            >
              <i className="ac-icon ac-icon--chevron-left ac-button__icon ac-button__icon--left" />
              {t('Terug')}
              <AcRipple theme={'dark'} size={'small'} />
            </AcButton>
          </AcColumn>

          <AcColumn xs={12} sm={6} className={'h-flex-h-align-end'}>
            <AcButton theme={'alpha'} type={'submit'}>
              {t('Volgende stap')}
              <AcRipple size={'small'} />
            </AcButton>
          </AcColumn>
        </AcRow>

        {loading && <AcLoader loading={loading} cover={true} />}
      </form>
    );
  }
}

export default withNamespaces()(AcParticipationStepInvestment);
