// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

// Imports => Controller
import AcAuthStepsController from './ac-auth-steps.controller.js';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcPasswordNotifications from '@atoms/ac-password-notifications/ac-password-notifications.web';

import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';

import AcTextInput from '@atoms/ac-text-input/ac-text-input.web.js';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

@inject('routing', 'store')
@observer
class AcAuthSteps extends AcAuthStepsController {
  render() {
    const { title, introduction, fields, actions, t, store: {toasters} } = this.props;

    const { queue: Toasters } = toasters;
    return (
      <form method="post" autoComplete="off" onSubmit={this.handleSubmit}>
        <AcRow noGutters={true} className={'h-padding-top-20'}>
          <AcColumn xs={12}>
            <AcHeading rank={5}>{title}</AcHeading>
          </AcColumn>
        </AcRow>
        {introduction && (
          <AcRow
            noGutters={true}
            className={'h-padding-bottom-20 h-margin-bottom-10'}
          >
            <AcColumn
              xs={{ size: 12 }}
              sm={{
                size: 10,
                offset: 1,
              }}
              md={{
                size: 8,
                offset: 2,
              }}
            >
              <AcRichContent
                content={
                  introduction.content ? introduction.content : introduction
                }
                options={introduction.options ? introduction.options : false}
              />
            </AcColumn>
          </AcRow>
        )}
        {fields &&
          fields.map((field, i) => {
            const Tag = field.tag ? field.tag : AcTextInput;
            return (
              <AcRow
                noGutters={true}
                className={'h-padding-bottom-20'}
                key={`ac-fields-${i}`}
              >
                <AcColumn
                  xs={{ size: 12 }}
                  sm={{
                    size: 10,
                    offset: 1,
                  }}
                  md={{
                    size: 8,
                    offset: 2,
                  }}
                >
                  <Tag {...field} />
                </AcColumn>
              </AcRow>
            );
          })}
        {Toasters &&
          Toasters.slice(0, 1).map((toast, i) => (
            <ReactCSSTransitionReplace
              transitionName="fade-wait"
              transitionEnterTimeout={1000}
              transitionLeaveTimeout={1000}
              key={`ac-toasters-${i}-${i}`} 
            >
              <AcRow
                noGutters={true}
                className={'h-padding-bottom-20'}
                key={`ac-toasters-${i}`}
              >
                <AcColumn
                  xs={{ size: 12 }}
                  sm={{
                    size: 10,
                    offset: 1,
                  }}
                  md={{
                    size: 8,
                    offset: 2,
                  }}
                >
                  <AcPasswordNotifications
                    title={toast.title}
                    description={toast.description}
                    variant={toast.variant}
                  />
                </AcColumn>
              </AcRow>
            </ReactCSSTransitionReplace>
          ))}
        {actions &&
          actions.map((action, i) => {
            return (
              <AcRow
                noGutters={true}
                className={'h-padding-bottom-20'}
                key={`ac-actions-${i}`}
              >
                <AcColumn xs={12}>
                  <AcButton {...action.button}>
                    {t(action.text)}
                    {action.ripple && <AcRipple {...action.ripple} />}
                  </AcButton>
                </AcColumn>
              </AcRow>
            );
          })}
      </form>
    );
  }
}

export default withNamespaces()(AcAuthSteps);
